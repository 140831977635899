#about {
  min-height: calc(100vh - 6.4rem);
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .aboutUs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 90%;
    margin: 2rem auto;

    .aboutUsTitle {
      margin-bottom: 1rem;
      font-size: 5rem;
      }
    .aboutUsSubtitle {
      font-size: 3rem;
      margin: 1.5rem 0;
      padding-bottom: 0.1rem;
      border-bottom: 3.1px solid rgb(196, 44, 44)
    }
    .aboutSerenPic {
      margin: 1rem;
      width: 16rem;
      border-radius: 1.75rem;
      box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
      }

    .aboutTextTop{
      font-weight: 600;
      font-size: 1.55rem;
    }
    .aboutUsDescription {
      font-size: 1.5rem;
      padding: 0 1rem;

      .description-two, .description-four  {
        width: 100vw;
        padding: 0 2.5rem;
        font-size: 1.7rem;
        font-weight: 600;
        .word-highlights{
          color: rgb(196, 44, 44);
          font-size: 1.8rem;
        }
      }
      .description-one, .description-three {
        width: 100vw;
        padding: 0 3rem;
        /* background: rgb(245, 244, 244); */
        font-size: 1.4rem;
      }
    }

  }
  .ourStaff{
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 2rem 0;


    .staffTitle{
      font-size: 3rem;
      margin-bottom: 1rem;
      margin-top: 4rem;
    }

    .staffImages{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      width: 100vw;

      .imgItem{
        display: flex;
        text-align: center;
        justify-content: center;
        margin: 0.75rem 0;
        padding: 1.2rem;

        .staffName{
          margin: 0.25rem 0;
          font-size: 1.6rem;
          /* color: #6f9a58; */
        }

        .staffJob{
          font-size: 1.2rem;
          font-weight: 600;
          margin-bottom: 0.5rem;
        }

         .staffDescription{
          font-size: 0.95rem;
          max-width: 22rem;
          margin-left: 1.5rem;
        }
        .imgItemSection{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

        }

        .staffImage{
          object-fit: cover;
          height: 19rem;
          width: 19rem;
          margin: 0.5rem;
          margin-bottom: 0.2rem;
          border-radius: 0.6rem;
          box-shadow: rgba(6, 24, 44, 0.177) 0px 0px 0px 2px, rgba(6, 24, 44, 0.347) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
        }
      }
    }
  }
  .link-wags-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 3rem;
    margin-top: 1rem;
    font-size: 1.2rem;

    .link-wags-section {
      display: flex;
      align-items: center;
      margin: 1rem;
      .link-wags{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 0.25rem;
        font-weight: 700;
        font-size: 1.4rem;
        color: rgb(196, 44, 44);
      }
      .link-wydr {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 0.25rem;
        font-weight: 700;
        font-size: 1.4rem;
        color: #4f733b;
      }
      .link-wags:hover{
        color: rgba(196, 44, 44, 0.723);
        opacity: 0.9;
      }
      .link-wydr:hover{
        color: #4f733bde;
        opacity: 0.9;
      }
      .paws {
        height: 1.9rem;
      }
    }
  }
}

@media screen and (max-width:640px) {
  #about {
    .ourStaff{
      .staffTitle{
        font-size: 2.5rem;
        margin-bottom: 0;
        margin-top: 0;
      }
        .staffImages{
          .imgItem{
            flex-direction: column;
            align-items: center;
            margin-bottom: 0;

            .staffImage{
              height: 22rem;
              width: 21rem;
              margin: 0.3rem;
              margin-top: 0;

            }
            .imgItemSection{
              /* margin-bottom: 0; */
              .staffName{
                font-size: 1.8rem;
                padding-top: 0.75rem;
              }
              .staffJob{
                font-size: 1.3rem;
              }
              .staffDescription{
                font-size: 0.95rem;
                max-width: 22rem;
                margin-left: 0;

              }
            }
          }
        }
    }
    .link-wags-container{
      font-size: 0.75rem;
      flex-direction: column;
      margin-top: 0;
      .link-wags-section {
        margin: 0.8rem 0;
        .link-wags {
          font-size: 0.9rem;
        }
        .link-wydr {
          font-size: 0.95rem;
        }
        .paws {
          height: 1.5rem;
        }
      }
    }
  }
}

@media screen and (max-width:560px) {
  #about{
    .aboutUs{
      .aboutUsTitle{
        font-size: 4rem;
      }
      .aboutUsSubtitle{
        font-size: 2rem;
      }
      .aboutUsDescription{
        font-size: 1.35rem;
        padding: 0.5rem 1rem;
        padding-bottom: 1rem;
        .aboutTextTop{
          font-size: 0.9rem;
        }


        .description-one, .description-three {
          padding: 0 2rem;
          font-size: 1.2rem;
        }

        .description-two, .description-four {
          font-size: 1.4rem;
          padding: 0 1.4rem;
          .word-highlights {
            font-size: 1.5rem;
          }
        }
      }

    }
    .aboutSerenPic{
      max-width: 60%;
      margin: 0.5rem;
    }
  }
}
