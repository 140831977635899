.carousel{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px;
  height: 500px;

  .slide{
    border-radius: 0.5rem;
    box-shadow: 0px 0px 7px #666;
    width: 100%;
    height: 100%;
    margin: 0 1.5rem;
    object-fit: cover;
  }
  .slide-hidden{
    display: none;
  }
  .arrow{
    position: absolute;
    width: 2rem;
    height: 2rem;
    color: white;
    filter: drop-shadow(0px 0px 5px #555);
    margin: 0 1.5rem;
  }
  .arrow:hover{
    cursor: pointer;
  }
  .arrow-left{
    left: 1rem;
  }
  .arrow-right{
    right: 1rem;
  }
  .indicators{
    display: flex;
    position: absolute;
    bottom: 1rem;

    .indicator{
      background-color: white;
      height: 0.5rem;
      width: 0.5rem;
      border-radius: 100%;
      border: none;
      outline: none;
      box-shadow: 0px 0px 5px #555;
      margin: 0 0.2rem;
      cursor: pointer;
    }
    .indicator-inactive {
      background-color: grey;
      height: 0.5rem;
      width: 0.5rem;
      border-radius: 100%;
      border: none;
      outline: none;
      box-shadow: 0px 0px 5px #555;
      margin: 0 0.2rem;
      cursor: pointer;
    }
  }
}

@media screen and (max-width:720px) {
  .carousel{
    width: 100%;
    .arrow.arrow-left{
      left: 0;
      margin-left: 0.75rem;
    }
    .arrow.arrow-right{
      right: 0;
      margin-right: 0.75rem;
    }
  }
}
