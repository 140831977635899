* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  background: transparent;
}
body {
  background: #FAF9F6;
  color: #1e1e1e;
  overflow: hidden;
  overflow-y: scroll;
  font-family: 'Arapey', serif;
}

/* .container {
  overflow-x: hidden;

} */
