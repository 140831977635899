#home {
  background: #eaeaea;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .homeItemsLeft{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem 2rem;
    width: 55%;
    margin-left: 2rem;
    /* background: #98a9b196; */
  }
  .homeText{
    font-size: 2.5rem;
    font-weight: 500;
    text-align: center;
    color: #353737;
    font-style: italic;

    .homeTextWord{
      color: rgb(74, 113, 54);
      font-size: 6rem;
      margin-left: 0.2rem;
      font-family: 'Dancing Script', cursive;
      font-weight: 500;
    }
  }
  .homeTitle{
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
    font-size: 3rem;
    color: #353737;
    font-weight: 500;
    font-style: italic;

  }

  .homeBtnLink{
    margin: 2rem;
    margin-left: 0;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    border-radius: 0.6rem;
    background: rgb(74, 113, 54);
    /* border: 1px solid #3e553123; */
    box-shadow: #475b5acf 0px 5px 15px;
    .btnText{
      font-weight: 700;
      font-size: 1rem;
      color: #effafc;
    }
  }
  .homeBtnLink:hover{
    background: rgba(74, 113, 54, 0.853);
    transition: 0.3s ease-in;
  }

  .homeItemsRight{
    padding: 1rem;
    margin: 2rem;
    margin-left: 0;
    display: flex;
    align-items: flex-start;
    .landingPic{
      height: 18rem;
      /* width: 28rem; */
      border-radius: 0.8rem;
      opacity: 0.8;
      margin-right: 1.5rem;
      border: none;
      /* box-shadow: rgba(32, 34, 31, 0.633) 0px 1px 2px 0px, rgba(42, 42, 42, 0.688) 0px 2px 4px 2px; */
    }
  }
}


@media screen and (max-width:860px) {
  #home{
    height: 100vh;
    flex-direction: column;
    overflow: hidden;
    /* padding: 4rem; */

    .homeItemsLeft{
      width: 100%;
      align-items: center;
      padding-top: 0;
      margin-left: 0;
    }
    .homeText{
      font-size: 2rem;
      margin-top: 2.2rem;
      margin-right: 0.5rem;
      .homeTextWord{
        font-size: 4rem;
      }
    }
    .homeTitle{
      margin-top: 1rem;
      font-size: 1.2rem;
      text-align: center;
    }
    .homeBtnLink{
      margin-bottom: 0.5rem;
      margin-top: 1.5rem;
      margin-left: 2.2rem;
      .btnText{
        font-size: 0.75rem;
      }
    }
    .homeItemsRight{
      margin-top: 0;
      margin-bottom: 0;
      padding: 0;
      padding-bottom: 2rem;
      .landingPic{
        height: 13rem;
        margin-top: 2rem;
        /* max-width: 90%; */
        margin-left: 2.5rem;
        display: flex;
        align-items: center;
      }
    }
  }
}
