#navbar {
  height: 3.2rem;
  /* width: 100vw; */
  padding: 1rem 1.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 3;
  background: #98a9b1;
  /* border-bottom: 2px solid #889297; */

  .logo {
    object-fit: contain;
    height: 3.5rem;
    width: auto;
    padding: 0.6rem;
    padding-left: 0.2rem;
  }
  .navbarDesktopLink {
    margin: 1rem;
    cursor: pointer;
    color: #faf9f6;
    font-size: 1.15rem;
  }
  .navbarDesktopLink:hover {
    opacity: 0.6;
    transition: all 0.3s ease;
  }

  .burgerMenu {
    display: none;
    height: 2rem;
    width: 2rem;
    cursor: pointer;
    margin-right: 0.25rem;
  }

  .navMenu {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 3rem;
    right: 0rem;
    z-index: 2;
    padding: 0.5rem;
    height: calc(100vh - 2rem);
    width: 100vw;
    background: #98a9b1;
    overflow: hidden;



    /* .navMenu:active {
      transition: all 2s ease;
    } */


    .navMenuLinksContainer {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 4rem;
    }

    .navMenuLink {
      color: #FAF9F6;
      /* font-weight: 600; */
      font-size: 1.8rem;
      padding: 1rem;
      margin: 0.25rem;
      border-radius: 0.5rem;
      margin: 1.5rem 0;
    }
    .navMenuLink:hover {
      opacity: 0.8;
      font-size: 2.2rem;
      transition: all 0.75s ease;
    }
  }
}
@media screen and (max-width:720px) {

  #navbar {
    .logo{
      height: 3rem;
      padding-left: 0;
    }
    .burgerMenu {
      display: flex;
      height: 1.8rem;
      width: 1.8rem;
    }
    .navbarDesktop {
      display: none;
    }
  }
}
