#contact {
  min-height: calc(100vh - 6.4rem);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1.5rem 0;

  .contactTitle {
    font-size: 3.5rem;
    color: #6f9a58;
    /* font-family: 'Dancing Script', cursive; */
    margin-bottom: 0.5rem
  }

  .contactDescription {
    padding: 0.25rem 1rem;
    /* padding-top: 0.5rem; */
    font-size: 1.3rem;
    font-weight: 700;
    /* color: #6f9a58; */
  }
  .contactDescriptionphone {
    color: #575d5e;
    font-size: 1.65rem;
    font-weight: 600;
    /* font-style: italic; */
  }

  .contactForm {
    margin: 1rem;
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60vw;
    max-width: 40rem;

    .name, .email, .msg {
      font-size: 1.15rem;
      width: 100%;
      max-width: 30rem;
      margin: 0.5rem;
      padding: 0.7rem 1rem;
      background: #e5e9ec;
      border: none;
      border-radius: 0.5rem;
    }
    .submitBtn {
      background: #6f9a58;
      color: #FAF9F6;
      border: none;
      font-weight: 600;
      border-radius: 0.5rem;
      margin-top: 1rem;
      padding: 0.75rem 3.5rem;
      transition: all 0.5s ease;
    }
    .submitBtn:hover {
      cursor: pointer;
      opacity: 0.6;
      transition: all 0.2s ease;
    }
  }
  .socialLinks{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .link{
    height: 2.5rem;
    margin: 0 0.5rem;
    opacity: 0.9;
  }
  .link:hover{
    opacity: 0.5;
    transition: all 0.5s ease;
  }

  @media screen and (max-width:560px) {
    .contactForm {
      width: 70vw;
      margin: 0.25rem 1rem;

      .submitBtn{
        margin-bottom: 1rem;
      }
    }
    .contactTitle {
      font-size: 2.5rem;
    }
    .contactDescriptionphone {
      font-size: 1.4rem;
      margin-top: 0.5rem;
    }
    .contactDescription {
      font-size: 0.9rem;
      margin-top: 0.5rem;
    }
    .submitBtn {
      margin-top: 0.75rem;
      padding: 0.75rem 3.5rem;
      font-size: 0.7rem;
    }
    .link{
      height: 2.25rem;
    }
  }
}
