#footer {
  /* width: 100vw; */
  height: 3.75rem;
  background: #98a9b1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #faf9f6;
  padding-bottom: 0.5rem;

  .footerLink {
    font-style: italic;
    font-weight: 500;
    color: #efe5df;
  }
  .footerLink:hover {
    cursor: pointer;
    opacity: 0.8;
    transition: all 0.2s ease;
  }
}

@media screen and (max-width:560px) {
  #footer {
    font-size: 0.65rem;
    padding-bottom: 0;
    height: 3.2rem;
  }
}
