#blog {
  min-height: calc(100vh - 6.4rem);
  display: flex;
  flex-direction: column;

  .locations {
    width: 85%;
    margin: auto;
    text-align: center;
    padding-top: 2rem;

    .locationsTitle{
      font-size: 2.5rem;
      /* color: #bc2931; */
      /* font-family: 'Dancing Script', cursive; */
    }
    .locationsDescription{
      margin-top: 1rem;
      font-size: 1.5rem;
      font-weight: 600;
      /* color: #6f9a58; */
    }
    .row {
      margin-top: 3%;
      display: flex;
      justify-content: space-between;
      .classLocation {
        flex-basis: 32.5%;
        border-radius: 10px;
        /* margin-bottom: 1rem; */
        position: relative;
        overflow: hidden;
      }
      .classLocation img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
      }
    }

    .layer {
      background: transparent;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      transition: 0.5s;
    }
    .layer:hover {
      background: #6f9a58;
      opacity: 0.9;
      transition: 0.5s;
    }
    .layer h3{
      width: 100%;
      font-weight: 500;
      margin-top: 5rem;
      color: #FAF9F6;
      font-size: 2rem;
      bottom: 0.5rem;
      left: 50%;
      transform: translateX(-50%);
      position: absolute;
      opacity: 0;
      transition: 0.5s;
    }
    .layer:hover h3{
      bottom: 49%;
      opacity: 1;
    }
    .layer p{
      width: 100%;
      font-weight: 500;
      margin-top: 5rem;
      color: #FAF9F6;
      font-size: 1.25rem;
      bottom: 0.5rem;
      left: 50%;
      transform: translateX(-50%);
      position: absolute;
      opacity: 0;
      transition: 0.5s;
    }
    .layer:hover p{
      bottom: 43%;
      opacity: 1;
    }
  }
  .funders{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 2rem 2rem;

    .fundersTitle{
      font-size: 2.5rem;
      margin-top: 2rem;
      margin-bottom: 0.5rem;
      text-align: center;
      /* color: #bc2931; */
      /* font-family: 'Dancing Script', cursive; */
    }
    .fundersDescription{
        margin-top: 1rem;
        font-size: 1.5rem;
        font-weight: 600;
        /* color: #6f9a58; */
    }

    .fundersList{
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      list-style: none;
      margin: 2rem 2rem;

      .fundersListItem{
        font-size: 1.25rem;
        margin: 0.3rem;
        border-right: 3px solid #6f9a58;
        padding-right: 0.3rem;
        font-weight: 500;
      }
    }
  }
  .celebs{
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .celebsTitle{
      font-size: 2.5rem;
      margin: 1rem 0;
      margin-top: 0;
      /* color: #bc2931; */
      /* font-family: 'Dancing Script', cursive; */
    }
    .carouselDescription{
        margin: 1rem 0 2rem;
        font-size: 1.5rem;
        font-weight: 600;
        /* color: #6f9a58; */
    }
    .celebspics{
      margin: 0 0.5rem;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;

      .carousel{
        width: 30rem;
        object-fit: contain;

      }
      .celebSidePic{
        width: 400px;
        height: 500px;
        margin: 0 1rem;
        border-radius: 0.75rem;
        object-fit: cover;
        box-shadow: 0px 0px 7px #666;
      }
    }
  }

  .nationalLottery {
    .natLotTitle {
      font-size: 2.5rem;
      margin-top: 2rem;
      margin-bottom: 1.5rem;
      text-align: center;
    }
    .natLotDescription {
      margin: 2.5rem 0;
      font-size: 1.5rem;
      font-weight: 600;
      text-align: center;
    }
    .pressLetterImage {
      display: flex;
      justify-content: center;
      margin: 2rem;
      margin-bottom: 0.5rem;
    }
  }
  .newLeaflet {
    .newLeafletTitle {
      font-size: 2.5rem;
      margin-top: 2rem;
      text-align: center;
    }
    .newLeafletDescription {
      margin: 2rem 0;
      font-size: 1.5rem;
      font-weight: 600;
      text-align: center;
    }
    .newLeafletImage {
      display: flex;
      justify-content: center;
      margin: 2rem;
      margin-bottom: 4rem;
    }
   }
  }


  @media screen and (max-width:1000px) {
    .row {
      .layer h3{
        font-size: 1.8rem;
        margin-bottom: 0.75rem;
      }
      .layer p{
        font-size: 0.8rem;
        margin-top: 0.25rem;
    }
    }
    .celebs{
      margin: 0 0.75rem;
      margin-top: 2rem;
      .celebsTitle{
        margin-top: 3rem;
      }
      .carouselDescription{
        font-size: 1.1rem;
        margin-top: 0;
      }
      .celebspics{
        .carousel{
          margin: 0 0.75;
          padding: 1rem;
          margin-bottom: 2rem;
          height: 34rem;
          width: auto;
        }
        .celebSidePic{
          display: none;
        }
      }
    }
  }
  @media screen and (max-width:560px) {
    .locations{
      .classLocation{
        margin-bottom: 1rem;
      }
      .locationsTitle {
        font-size: 2.5rem;
        margin-top: 0.75rem;
      }

      .locationsDescription{
        font-size: 1rem;
      }
      .row {
        margin-top: 4%;
        flex-direction: column;
      }
    }
    .funders{
      margin-bottom: 0.5rem;
      .fundersTitle{
        font-size: 2.25rem;
        margin: 0 0.75rem;
        margin-top: 3rem
      }
      .fundersDescription{
        font-size: 1.1rem;
      }
      .fundersList{
        .fundersListItem{
          font-size: 0.75rem;
          margin-bottom: 0.75rem;
        }
      }
    }

    .newLeaflet {
      .newLeafletTitle {
        font-size: 2.25rem;
        margin-top: 4.5rem;
      }
      .newLeafletDescription {
        margin: 2rem 0;
        margin-bottom: 1.5rem;
        font-size: 1rem;
        font-weight: 600;
      }
      .newLeafletImage {
        margin-bottom: 2rem;
        height: 29rem;
        /* width: auto; */
      }
    }

    .nationalLottery {
      .natLotTitle {
        font-size: 2.25rem;
        margin-top: 5rem;
      }
      .natLotDescription {
        margin: 2rem 0;
        font-size: 1rem;
        font-weight: 600;
      }
      .pressLetterImage {
        margin-bottom: 2rem;
        height: 27rem;
        width: auto;
      }
    }
}
